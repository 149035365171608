
.typewriter h1 {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em; /* Adjust as needed */
  animation: 
    typing 3.5s steps(40, end),
    blink-caret .75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width:100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; }
}
.toggleDarkBtn {
  position: relative;
  display: inline-block;
  width: 65px;
  height: 41px;
}
.toggleDarkBtn input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slideBtnTg {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(241, 40, 40);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.slideBtnTg:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  left: 5px;
  bottom: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: white;
}
input:checked + .slideBtnTg {
  background-color: #1d1e1f;
}
input:focus + .slideBtnTg {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slideBtnTg:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slideBtnTg.round {
  border-radius: 34px;
}
.slideBtnTg.round:before {
  border-radius: 50%;
}