/* You can add this to your main CSS file or use it within the component using inline styles */

/* Use the @apply directive to define custom CSS classes */
.tag-button {
    @apply inline-block m-1 px-4 py-2 rounded border border-gray-300 bg-gray-100 cursor-pointer transition-colors duration-200;
  }
  
  .tag-button:hover {
    @apply bg-gray-200;
  }
  
  .tag-button-selected {
    @apply bg-blue-500 text-white;
  }
  
  .tag-button-selected:hover {
    @apply bg-blue-600;
  }